import request from '@/router/axios';
import { pvhApiBase } from "@/config/env";

//��ѯ
export const List = (name, shortName, code, address, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetAffiliatedCorporations",
        method: "get",
        params: {
            "name": name,
            "shortName": shortName,
            "code": code,
            "address": address,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//
export const add = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostAffiliatedCorporation",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const edit = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutAffiliatedCorporation",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const del = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteAffiliatedCorporation",
        method: "delete",
        params: {
            id
        }
    })
}


export const GetDefaultCorp = (CorporationId, Type) => {
    return request({
        url: pvhApiBase + "Tracing/GetDefaultAffiliatedCorporation",
        method: "get",
        params: {
            "CorporationId": CorporationId,
            "Type": Type
        }
    })

}